import params from "~/env";
import { AlgoliaECOMService } from "./algoliaECOM.service";
import type { Product } from "~/types/ecom/product/product.type";

export class ProductService {


  async getProductByCode(id: number): Promise<{data: Product} | any> {
    try {
      const response = await useAjaxEcom(`/products/${id}`);
      return response.data;
    } catch (error) {
      useErrorHandler(error, "critical");
    }
  }

  async getProducts(id: number[]): Promise<{data: Product[]} | any> {
    try {
        const response = await useAjaxEcom("/products/getProducts", {
            method: "post",
            params: {
              products: id,
            },
          });
      return response.data
    } catch (error) {
        useErrorHandler(error, 'critical')
        return error
    }
  }

  async getStocks(id: number[], branch_id?: string[])  {
    try {

        const response = await useAjaxEcom("/stock/getStock", {
            method: "post",
            params: {
              products: id,
              sites: ["WW"],
            },
          });
      return response.data
    } catch (error) {
      useErrorHandler(error, "high");
      return error
    }
  }

  async getDeliveryDetails(id: number, sites?:string){
    try{
     
        if(sites){
          //  default_sites = [ sites, ...default_sites]
        }
        const response = await useAjaxEcom(`/products/${id}/delivery`, {
            query: {
                site: sites
            },
        })
        return response.data
    }catch(error){
        useErrorHandler(error, 'low')
        return error
    }
  }
}
